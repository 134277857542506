import axios from 'axios';
import moment from 'moment';
import * as types from './types';

const actions = {
  async fetchInitial({ commit, dispatch, state }) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      await dispatch('fetchDashboardSettings');
      await dispatch('fetchStatisticDate', state);
      commit(types.FETCH_INITIAL_FULFILLED);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR, error);
      return Promise.reject(error);
    }
  },

  async fetchTopClients({ commit, rootState }, { date_from: dateFrom, date_to: dateTo, currency_code: currencyCode }) {
    commit(types.FETCH_TOP_CLIENTS_PENDING, true);
    try {
      const queryParameter = {
        start_date: dateFrom,
        end_date: dateTo,
        currency_code: currencyCode,
      };
      const response = await axios.get(
        `/accounts/${rootState.definitions.account.account.uuid}/dashboard/top3_client`,
        { params: queryParameter },
      );
      commit(types.FETCH_TOP_CLIENTS, response);
      commit(types.FETCH_TOP_CLIENTS_PENDING, false);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.FETCH_TOP_CLIENTS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchTopProducts({ commit, rootState }, {
    date_from: dateFrom,
    date_to: dateTo,
    currency_code: currencyCode,
  }) {
    commit(types.FETCH_TOP_PRODUCTS_PENDING, true);
    try {
      const queryParameter = {
        start_date: dateFrom,
        end_date: dateTo,
        currency_code: currencyCode,
      };
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/dashboard/top3_product`, { params: queryParameter });
      commit(types.FETCH_TOP_PRODUCTS, response);
      commit(types.FETCH_TOP_PRODUCTS_PENDING, false);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.FETCH_TOP_PRODUCTS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchBalances({ commit, rootState }, {
    group_by: groupBy,
    date_from: dateFrom,
    date_to: dateTo,
    currency_code: currencyCode,
  }) {
    commit(types.FETCH_BALANCE_PENDING, true);
    try {
      const queryParameter = {
        start_date: dateFrom,
        end_date: dateTo,
        group_by: groupBy,
        currency_code: currencyCode,
        is_receipt: 0,
      };
      const [customerBalance, suppliersBalance] = await Promise.all([
        axios.get(`/accounts/${rootState.definitions.account.account.uuid}/dashboard/chart_data`, { params: queryParameter }),
        axios.get(`/accounts/${rootState.definitions.account.account.uuid}/dashboard/chart_data`, { params: { ...queryParameter, ...{ is_receipt: 1 } } }),
      ]);
      commit(types.FETCH_CUSTOMER_BALANCE, customerBalance);
      commit(types.FETCH_SUPPLIER_BALANCE, suppliersBalance);
      commit(types.FETCH_BALANCE_PENDING, false);
      return Promise.resolve({ customerBalance, suppliersBalance });
    } catch (error) {
      commit(types.FETCH_BALANCE_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchCustomerBalanceData({ commit, rootState }, {
    rangeGroup,
    start_date: startDate,
    end_date: endDate,
    currency_code: currencyCode,
  }) {
    commit(types.FETCH_CUSTOMER_BALANCE_PENDING, true);
    try {
      const queryParameter = {
        start_date: startDate,
        end_date: endDate,
        group_by: rangeGroup,
        currency_code: currencyCode,
        is_receipt: 0,
      };
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/dashboard/chart_data`, { params: queryParameter });
      commit(types.FETCH_CUSTOMER_BALANCE, response);
      commit(types.FETCH_CUSTOMER_BALANCE_PENDING, false);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.FETCH_CUSTOMER_BALANCE_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchSuppliersBalanceData({ commit, rootState }, {
    rangeGroup,
    start_date: startDate,
    end_date: endDate,
    currency_code: currencyCode,
  }) {
    commit(types.FETCH_SUPPLIER_BALANCE_PENDING, true);
    try {
      const queryParameter = {
        start_date: startDate,
        end_date: endDate,
        group_by: rangeGroup,
        currency_code: currencyCode,
        is_receipt: 1,
      };
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/dashboard/chart_data`, { params: queryParameter });
      commit(types.FETCH_SUPPLIER_BALANCE, response);
      commit(types.FETCH_SUPPLIER_BALANCE_PENDING, false);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.FETCH_SUPPLIER_BALANCE_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchTotals({ commit, rootState }, {
    currency_code: currencyCode,
    type,
  }) {
    commit(types.FETCH_TOTALS_PENDING, { type, isPending: true });
    try {
      const queryParameter = {
        currency_code: currencyCode,
      };
      const data = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/dashboard/totals/${type}`, { params: queryParameter });
      commit(types.FETCH_TOTALS, data);
      commit(types.FETCH_TOTALS_PENDING, { type, isPending: false });
      return Promise.resolve(data);
    } catch (error) {
      commit(types.FETCH_TOTALS_PENDING, { type, isPending: false });
      return Promise.reject(error);
    }
  },
  async fetchStatisticDate({ commit, rootState }) {
    try {
      const data = {
        last_period: 'YEARS',
      };
      const form = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/dashboard/static_data`, { params: data });
      commit(types.FETCH_FORM, form);
      return Promise.resolve(form);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchActivities({ commit, rootState }) {
    commit(types.FETCH_ACTIVITIES_PENDING, true);
    try {
      const params = {
        date_range_from: moment().subtract(3, 'months').format('YYYY-MM-DD'),
        per_page: 5,
        page: 1,
      };
      const url = `/accounts/${rootState.definitions.account.account.uuid}/pagination/reports/activities`;
      const response = await axios.get(url, { params });
      commit(types.FETCH_ACTIVITIES, response);
      commit(types.FETCH_ACTIVITIES_PENDING, false);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.FETCH_ACTIVITIES_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchDashboardSettings({ commit, rootState }) {
    commit(types.FETCH_DASHBOARD_SETTINGS_PENDING, true);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/account_user_store/dashboard`;
      const data = await axios.get(url);
      commit(types.FETCH_DASHBOARD_SETTINGS, data);
      commit(types.FETCH_DASHBOARD_SETTINGS_PENDING, false);
    } catch (error) {
      commit(types.FETCH_DASHBOARD_SETTINGS, {});
      commit(types.FETCH_DASHBOARD_SETTINGS_PENDING, false);
    }
  },
  async storeDashboardSettings({ rootState }, data) {
    try {
      // quick fix 302
      await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/session/keep_alive`);
      const url = `/accounts/${rootState.definitions.account.account.uuid}/account_user_store/dashboard`;
      await axios.post(url, { value: JSON.stringify(data) });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchFirstSteps({ rootState, commit }) {
    commit(types.FETCH_FIRST_STEPS_PENDING, true);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/dashboard/first_steps`;
      const data = await axios.get(url);
      commit(types.FETCH_FIRST_STEPS, data);
      commit(types.FETCH_FIRST_STEPS_PENDING, false);
    } catch (error) {
      commit(types.FETCH_FIRST_STEPS_PENDING, false);
    }
  },
  async fetchStatusTotal({ commit, rootState }, {
    currency_code: currencyCode,
  }) {
    commit(types.FETCH_DASHBOARD_STATUS_TOTAL_PENDING, true);
    try {
      const queryParameter = {
        currency_code: currencyCode,
      };
      const data = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/dashboard/totals/status`, { params: queryParameter });
      commit(types.FETCH_DASHBOARD_STATUS_TOTAL, data);
      commit(types.FETCH_DASHBOARD_STATUS_TOTAL_PENDING, false);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_DASHBOARD_STATUS_TOTAL_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchPosData({ commit, rootState }, {
    group_by: groupBy,
    date_from: dateFrom,
    date_to: dateTo,
    currency_code: currencyCode,
  }) {
    commit(types.FETCH_DASHBOARD_POS_DATA_PENDING, true);
    try {
      const queryParameter = {
        start_date: dateFrom,
        end_date: dateTo,
        group_by: groupBy,
        currency_code: currencyCode,
      };
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/dashboard/pos_data`, { params: queryParameter });
      commit(types.FETCH_DASHBOARD_POS_DATA, response);
      commit(types.FETCH_DASHBOARD_POS_DATA_PENDING, false);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_DASHBOARD_POS_DATA_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchSales({ commit, rootState }, {
    date_from: dateFrom,
    date_to: dateTo,
    currency_code: currencyCode,
  }) {
    commit(types.FETCH_DASHBOARD_SALES_PENDING, true);
    try {
      const queryParameter = {
        start_date: dateFrom,
        end_date: dateTo,
        currency_code: currencyCode,
      };
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/dashboard/sales`, { params: queryParameter });
      commit(types.FETCH_DASHBOARD_SALES, response);
      commit(types.FETCH_DASHBOARD_SALES_PENDING, false);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_DASHBOARD_SALES_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchLiquidity({ commit, rootState }, {
    date_from: dateFrom,
    date_to: dateTo,
    currency_code: currencyCode,
  }) {
    commit(types.FETCH_LIQUIDITY_SALES_PENDING, true);
    try {
      const queryParameter = {
        start_date: dateFrom,
        end_date: dateTo,
        currency_code: currencyCode,
      };
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/dashboard/liquidity`, { params: queryParameter });
      commit(types.FETCH_LIQUIDITY_SALES, response);
      commit(types.FETCH_LIQUIDITY_SALES_PENDING, false);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_LIQUIDITY_SALES_PENDING, false);
      return Promise.reject(error);
    }
  },
};

export default actions;
