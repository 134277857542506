import productGroup from '@/store/modules/product-group';
import productGroups from '@/store/modules/product-groups';
import userInterface from '@/store/modules/user-interface';
import Vue from 'vue';
import Vuex from 'vuex';

import basics from './basics';
import messages from './messages';
import abacus from './modules/abacus';
import abanet from './modules/abanet';
import accounting from './modules/accounting';
import activities from './modules/activities';
import addresses from './modules/addresses';
import deeppayAdmin from './modules/admin/deeppay-admin';
import notifications from './modules/admin/notifications';
import comments from './modules/comments';
import companies from './modules/companies';
import customers from './modules/customers';
import dashboard from './modules/dashboard';
import definitions from './modules/definitions';
import documentDesigner from './modules/document-designer';
import finances from './modules/finances';
import inbox from './modules/inbox';
import products from './modules/products';
import reports from './modules/reports';
import suppliers from './modules/suppliers';
import swiss21 from './modules/swiss21';
import user from './modules/user';
import valueStore from './modules/valueStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    abacus,
    abanet,
    accounting,
    activities,
    addresses,
    basics,
    comments,
    companies,
    customers,
    dashboard,
    deeppayAdmin,
    definitions,
    documentDesigner,
    finances,
    inbox,
    messages,
    notifications,
    products,
    productGroups,
    productGroup,
    reports,
    suppliers,
    swiss21,
    user,
    userInterface,
    valueStore,
  },
});
