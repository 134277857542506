import axios from 'axios';

const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_FULFILLED';

const FETCH_LIST_PENDING = 'FETCH_LIST_PENDING';
const FETCH_LIST_FULFILLED = 'FETCH_LIST_FULFILLED';
const FETCH_LIST_ERROR = 'FETCH_LIST_ERROR';

const FETCH_BANK_ACCOUNT_EXPORT_PENDING = 'FETCH_BANK_ACCOUNT_EXPORT_PENDING';
const FETCH_BANK_ACCOUNT_EXPORT_FULFILLED = 'FETCH_BANK_ACCOUNT_EXPORT_FULFILLED';
const FETCH_BANK_ACCOUNT_EXPORT_ERROR = 'FETCH_BANK_ACCOUNT_EXPORT_ERROR';

export default {
  namespaced: true,
  state: {
    initialPending: false,
    fetchListPending: false,
    list: [],
    fetchBankAccountExportPending: false,
  },
  mutations: {
    [FETCH_INITIAL_PENDING](state) {
      state.initialPending = true;
    },
    [FETCH_INITIAL_FULFILLED](state) {
      state.initialPending = false;
    },
    [FETCH_INITIAL_ERROR](state) {
      state.initialPending = false;
    },
    [FETCH_LIST_PENDING](state) {
      state.fetchListPending = true;
    },
    [FETCH_LIST_FULFILLED](state, { data }) {
      state.list = data;
      state.fetchListPending = false;
    },
    [FETCH_LIST_ERROR](state) {
      state.fetchListPending = false;
    },
    [FETCH_BANK_ACCOUNT_EXPORT_PENDING](state) {
      state.fetchBankAccountExportPending = true;
    },
    [FETCH_BANK_ACCOUNT_EXPORT_FULFILLED](state) {
      state.fetchBankAccountExportPending = false;
    },
    [FETCH_BANK_ACCOUNT_EXPORT_ERROR](state) {
      state.fetchBankAccountExportPending = false;
    },
  },
  actions: {
    async fetchInitial({ commit, dispatch }) {
      commit(FETCH_INITIAL_PENDING);
      try {
        await Promise.all([
          dispatch('fetchList'),
        ]);

        commit(FETCH_INITIAL_FULFILLED);
        return Promise.resolve();
      } catch (error) {
        commit(FETCH_INITIAL_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchList({ rootState, commit }) {
      commit(FETCH_LIST_PENDING);
      try {
        const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/payments/export/bankaccounts`);
        commit(FETCH_LIST_FULFILLED, response);
        return Promise.resolve();
      } catch (error) {
        commit(FETCH_LIST_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchBankAccountExport({ rootState, commit, dispatch }, uuid) {
      commit(FETCH_BANK_ACCOUNT_EXPORT_PENDING);
      try {
        const response = await axios({
          url: `/accounts/${rootState.definitions.account.account.uuid}/ababank/export_payments/${uuid}`,
          method: 'GET',
          responseType: 'blob',
        });
        commit(FETCH_BANK_ACCOUNT_EXPORT_FULFILLED);
        dispatch('fetchList');
        return Promise.resolve(response);
      } catch (error) {
        commit(FETCH_BANK_ACCOUNT_EXPORT_ERROR, error);
        return Promise.reject(error);
      }
    },
  },
};
